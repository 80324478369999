const { STATUS } = require('./schemas/stock');

const _DEFAULT_SHIPPING_METHOD = 'HOME_DELIVERY';
const _DEFAULT_SENDING_TYPE = 'Domicilio';
const _DETAULT_LOCATION = 'España peninsular, Mallorca';

function updatePriceProductPreviewFromMarketplace(stock, salesReference) {
  if (!stock || !salesReference) {
    return;
  }
  const mkp_preferred = stock.mkp_preferred?.[salesReference];
  if (!mkp_preferred?._price) {
    return;
  }

  return {
    unit_price: mkp_preferred._stroked_price || mkp_preferred._price,
    unit_sale_price: mkp_preferred._price,
    onsale: mkp_preferred._on_sale,
    discount: mkp_preferred._discount,
  };
}

function updatePriceFromMarketplate(stock, priceData, activeSku) {
  if (!stock || !priceData || !activeSku) {
    return false;
  }
  const { hasPreferredProducts } = hasPreferredMKPInfo(stock);
  if (!hasPreferredProducts) {
    return false;
  }
  const prioritizedPriceInfo = getPreferredPriceInfo(stock, activeSku);
  if (!prioritizedPriceInfo?._price) {
    return false;
  }
  priceData.unit_price      = prioritizedPriceInfo._stroked_price || prioritizedPriceInfo._price;
  priceData.unit_sale_price = prioritizedPriceInfo._price;
  priceData.onsale          = prioritizedPriceInfo._on_sale;
  priceData.discount        = prioritizedPriceInfo._discount;
  return true;
}

function updateVariantPriceFromMarketplace(stock, variant) {
  const stockReference = variant?.stockReference;
  if (!stock || !stockReference) {
    return false;
  }
  const { hasPreferredProducts } = hasPreferredMKPInfo(stock);
  if (!hasPreferredProducts) {
    return false;
  }
  const prioritizedPriceInfo = getPreferredPriceInfo(stock, stockReference);
  if (!prioritizedPriceInfo?._price) {
    return false;
  }
  variant.price       = prioritizedPriceInfo._stroked_price || prioritizedPriceInfo._price;
  variant.sale_price  = prioritizedPriceInfo._price;
  variant.onsale      = prioritizedPriceInfo._on_sale;
  variant.discount    = prioritizedPriceInfo._discount;
  return true;
}

function getReferredMKPPrice(fullStock, activeSku) {
  const {hasPreferredProducts ,productsWithPreferredInfo} = hasPreferredMKPInfo(fullStock)
  if (!hasPreferredProducts){
    return false;
  }
  const product = activeSku ? activeSku : productsWithPreferredInfo[0];
  if(!product){
    return false;
  } 
  const prices = getPreferredPriceInfo(fullStock, product);
  if (!prices?._price){
    return false;
  }
  return {
    unit_price:      prices._stroked_price || prices._price,
    unit_sale_price: prices._price,
    onsale:          prices._on_sale,
    discount:        prices._discount,
    unit_price_agrupation: prices.unit_price,
    unit_price_pum: prices.unit_price_pum,
  } 
}


function hasPreferredMKPInfo(stock) {
  const ids = Object.keys(stock.products);
  const productsWithPreferredInfo = ids.filter(function (id) {
    return (
      Object.keys(stock.products[id].mkp_preferred || {}).length > 0 &&
      stock.products[id].status?.toUpperCase() === STATUS.ADD
    );
  });
  const hasPreferredProducts = productsWithPreferredInfo && productsWithPreferredInfo.length;

  return {
    hasPreferredProducts,
    productsWithPreferredInfo,
  };
}

function hasPriorizatedShippingInfo(stock, offerType) {
  const ids = Object.keys(stock.products);  
  const productsWithPriorizatedShippingInfo = ids.filter(function (id) {
    if(offerType && stock.products[id].priorizated_shipping) {
      const prioritizedShippingInfo = stock.products[id]?.priorizated_shipping?.filter((mkp) => {
        return mkp.shipping_method === _DEFAULT_SHIPPING_METHOD && mkp.type === offerType;
      });

      return stock.products[id].status === 'ADD' && prioritizedShippingInfo && prioritizedShippingInfo.length > 0;
    }
    else return stock.products[id].has_prioritized_shipping && stock.products[id].status === 'ADD';
  });
  const hasPreferredProducts = productsWithPriorizatedShippingInfo && productsWithPriorizatedShippingInfo.length;
  
  return {
    hasPreferredProducts,
    productsWithPriorizatedShippingInfo,
  };
}

function getPreferredPriceInfo(stock, selectedMkpProductId) {
  const preferredPriceInfo = stock.products[selectedMkpProductId].mkp_preferred;
  return preferredPriceInfo?.[selectedMkpProductId];
}

function updateShippingDataFromMarketplace(stock, shippingInfo) {
  if (!stock) return;
  const { hasOnePrioritizedProduct, productsWithPrioritizedShippingInfo } = hasPrioritizedShippingInfo(stock);

  if (!hasOnePrioritizedProduct) return;

  const selectedMkpProductId = productsWithPrioritizedShippingInfo[0];
  var prioritizedShippingInfo = getPrioritizedShippingInfo(stock, selectedMkpProductId);
  updateShippingPriceFromMarketplace(prioritizedShippingInfo, stock.type, shippingInfo, selectedMkpProductId);
  updateShippingDatesFromMarketplace(shippingInfo);
}

function updateShippingDatesFromMarketplace(shippingInfo) {
  if (shippingInfo && shippingInfo.marketplace_shippings) {
    shippingInfo.delivery_time_with_stock = shippingInfo.marketplace_shippings.delivery_time_with_stock;
    shippingInfo.delivery_time_without_stock = shippingInfo.marketplace_shippings.delivery_time_without_stock;
    shippingInfo.delivery_time_with_stock_exact_time =
      shippingInfo.marketplace_shippings.delivery_time_with_stock_exact_time;
    shippingInfo.delivery_time_without_stock_exact_time =
      shippingInfo.marketplace_shippings.delivery_time_without_stock_exact_time;
  }
}

function updateShippingDatesFromMarketplacePreferred(stock, shippingInfo, color, activeSku) {    
  if (!stock || !shippingInfo || !color) return false;
  
  const { hasPreferredProducts, productsWithPriorizatedShippingInfo } = hasPriorizatedShippingInfo(stock, 'MKP');

  if (!hasPreferredProducts) return false;  

  const product               = activeSku ? activeSku : productsWithPriorizatedShippingInfo[0];
  const prioritizedShippingInfo = getPrioritizedShippingInfo(stock, product);  
  if (!prioritizedShippingInfo || !prioritizedShippingInfo.offer) {
    return false;
  }  
  const selectedVariantData = color && Array.isArray(color.variants) && color.variants.find((variant) => {
    return variant.stockReference == product;
  });
  
  if(!selectedVariantData || !selectedVariantData.providers) return;

  const providerData = selectedVariantData.providers.find((provider) => {    
    return provider.offer.offer_id === prioritizedShippingInfo.offer;
  });  
  
  shippingInfo.delivery_time_with_stock               = providerData.delivery_time_with_stock 
  shippingInfo.delivery_time_without_stock            = providerData.delivery_time_without_stock;
  shippingInfo.delivery_time_with_stock_exact_time    = providerData.delivery_time_with_stock_exact_time;
  shippingInfo.delivery_time_without_stock_exact_time = providerData.delivery_time_without_stock_exact_time;
  return true;
}

function updateShippingPriceFromMarketplace(prioritizedShippingInfo, stockType, shippingInfo, selectedMkpProductId) {
  const mkpShippingProviders =
    shippingInfo &&
    shippingInfo.marketplace_shippings &&
    shippingInfo.marketplace_shippings.marketplace_shipping_providers;

  if (!mkpShippingProviders) {
    return;
  }
  console.log(prioritizedShippingInfo);
  let selectedProvider;
  if (stockType === 'product') {
    selectedProvider = processProductStockType(mkpShippingProviders, prioritizedShippingInfo);
    if (!selectedProvider) {
      return;
    }
  } else if (stockType === 'reference') {
    selectedProvider = processReferenceStockType(selectedMkpProductId, mkpShippingProviders, prioritizedShippingInfo);
  }
  if (!selectedProvider) return;
  
  const shippingPrice = getShippingPriceByProvider(selectedProvider, _DEFAULT_SENDING_TYPE, _DETAULT_LOCATION);
  const sendingTypeToBeUpdated = shippingInfo.available.find((sendingType) => {
    return sendingType.place.indexOf(_DETAULT_LOCATION) !== -1;
  });
  if (sendingTypeToBeUpdated && Array.isArray(sendingTypeToBeUpdated.data)) {
    sendingTypeToBeUpdated.data
      .filter((data) => {
        return data.store.indexOf(_DEFAULT_SENDING_TYPE) !== -1;
      })
      .forEach((sendingElement) => {
        sendingElement.values.cost = shippingPrice;
      });
  }
}

function hasPrioritizedShippingInfo(stock) {
  const ids = Object.keys(stock.products);
  const productsWithPrioritizedShippingInfo = ids.filter(function (id) {
    return stock.products[id].has_prioritized_shipping;
  });
  const hasOnePrioritizedProduct =
    productsWithPrioritizedShippingInfo &&
    productsWithPrioritizedShippingInfo.length &&
    productsWithPrioritizedShippingInfo.length === 1;

  return {
    hasOnePrioritizedProduct,
    productsWithPrioritizedShippingInfo,
  };
}

function getPrioritizedShippingInfo(stock, selectedMkpProductId) {
  const prioritizedShippingInfo = stock.products[selectedMkpProductId]?.priorizated_shipping?.filter((mkp) => {
      return mkp.shipping_method === _DEFAULT_SHIPPING_METHOD && mkp.type === 'MKP';
    });
  if(!prioritizedShippingInfo || !prioritizedShippingInfo.length) return;
  
  return prioritizedShippingInfo[0];
}

function processProductStockType(mkpShippingProviders, prioritizedShippingInfo) {
  const selectedShippingByProduct = mkpShippingProviders.filter(function (shippingFor) {
    const hasProviders = shippingFor && shippingFor.providers && shippingFor.providers.length;
    return (
      hasProviders &&
      shippingFor.providers.filter(function (provider) {
        return provider.offer && provider.offer.offer_id === prioritizedShippingInfo.offer;
      }).length > 0
    );
  });
  if (!selectedShippingByProduct.length) {
    return;
  } else {
    return selectedShippingByProduct[0];
  }
}

function processReferenceStockType(selectedMkpProductId, mkpShippingProviders, prioritizedShippingInfo) {
  const referenceId = selectedMkpProductId.split(':')[0];
  let selectedShipping = mkpShippingProviders.filter(function (shippingFor) {
    return shippingFor.gtin.trim() === referenceId.trim();
  });
  if (selectedShipping && selectedShipping.length) {
    selectedShipping = selectedShipping[0];
  }
  if (selectedShipping && selectedShipping.providers && selectedShipping.providers.length) {
    var selectedProviderByRef = selectedShipping.providers.filter(function (provider) {
      return provider.offer && provider.offer.offer_id && provider.offer.offer_id === prioritizedShippingInfo.offer;
    });
    if (selectedProviderByRef && selectedProviderByRef.length) {
      return selectedProviderByRef[0];
    }
  }
}

function getShippingPriceByProvider(provider, shippingType, shippingLocation) {
  if (!provider || !provider.available || !shippingType) return;
  let shipping = provider.available.filter(function (type) {
    return type.title && type.title === shippingType;
  });
  if (shipping && shipping.length) {
    shipping = shipping[0];
  } else {
    return;
  }
  const locations = shipping.locations;
  let selectedLocation = locations.filter(function (location) {
    return location.title === shippingLocation;
  });
  if (selectedLocation && selectedLocation.length) {
    selectedLocation = selectedLocation[0];
  } else {
    return;
  }
  let price = selectedLocation.shipping;
  if (price && price.indexOf('EUR') !== -1) {
    price = price.replace('EUR', '€');
  }
  return price;
}

module.exports = {
  getReferredMKPPrice,
  updatePriceFromMarketplate,
  updateShippingDataFromMarketplace,
  updatePriceProductPreviewFromMarketplace,
  updateShippingDatesFromMarketplacePreferred,
  updateVariantPriceFromMarketplace,
};
